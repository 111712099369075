/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"

type ExperienceCardProps = {
  link: string
  title: string
  children: React.ReactNode
  bg: string
  imgSrc: string
  timeline: string
  jobTitle: string
}

let icons = {
  fidelity:
    "https://media-exp1.licdn.com/dms/image/C560BAQEp8IZ2oEGxKA/company-logo_200_200/0?e=1608163200&v=beta&t=AvKnzhv0DWChEoEJpVWEgjLwsQopcZfd_HQdSRf3Yj4",
  abjDrones:
    "https://media-exp1.licdn.com/dms/image/C4E0BAQGqqcvPvpWriQ/company-logo_200_200/0?e=1608163200&v=beta&t=O66ayCR62_OcqWpBwOdoeyVSdrpzo8J4iMDWmluZQlk",
  downtime:
    "https://media-exp1.licdn.com/dms/image/C4E0BAQHd6gTRBEeEEw/company-logo_200_200/0?e=1608163200&v=beta&t=-YC8OwFVt8ZX0wXPX4Qm_RkLf98oMiCkgaKCBk11npQ",
  compunnel:
    "https://media-exp1.licdn.com/dms/image/C560BAQG5lfJz54RRDQ/company-logo_200_200/0?e=1608163200&v=beta&t=AWuY22W7ru_0d5I1QONIJKLd1zI3r56J8zLJWQldD_0",
}

const ExperienceCard = ({
  link,
  title,
  children,
  bg,
  imgSrc,
  timeline,
  jobTitle,
}: ExperienceCardProps) => (
    <article
      sx={{
        background: "#17141d",
        boxShadow: "-1rem 0 3rem #000",
        width: `100%`,
        position: `relative`,
        textDecoration: `none`,
        borderRadius: `lg`,
        fontSize: [10, 10, 11, 12, 15, 18],
        lineHeight: 1,
        px: 4,
        py: [4, 5],
        color: `white`,
        transition: `all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important`,
        "&:hover": {
          color: `white !important`,
          transform: `translateY(-5px)`,
          boxShadow: `xl`,
        },
      }}
    >
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: "75px 1fr",
          alignItems: "center",
          position: "relative",
        }}
      >
        <a
          sx={{
            gridArea: "auto",
            alignSelf: "start",
            position: "relative",
            boxSizing: "border-box",
          }}
          href="#"
        >
          <img
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              display: "block",
              overflow: "hidden",
              margin: "16px 10px",
              background: "white",
            }}
            src={icons[imgSrc]}
          />
        </a>
        <svg
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "60px",
            height: "48px",
            fill: "none",
            stroke: "#ff8a00",
            strokeWidth: 8,
            strokeLinecap: "round",
            pointerEvents: "none",
          }}
          viewBox="0 0 106 57"
        >
          <path d="M102 4c0 27.1-21.9 49-49 49S4 31.1 4 4"></path>
        </svg>

        <div
          sx={{
            gridArea: "auto",
            boxSizing: "border-box",
          }}
        >
          <div
            sx={{
              fontStyle: "normal",
              fontWeight: "700",
              color: "#7a7a8c",
            }}
          >
            {timeline}
          </div>
          {title}
        </div>
      </div>

      <header
        sx={{
          marginBottom: "auto",
          ul: {
            margin: "auto",
            color: "#7a7a8c",
            paddingLeft: "15px",
            b: {
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "1.2rem",
              textTransform: "uppercase",
              position: "relative",
              background:
                "linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%)",
              WebkitTextFillColor: "transparent",
              WebkitBackgroundClip: "text",
              // "&:hover": {
              //   background:
              //     "linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(53,169,255,1) 100%)",
              //   textShadow: "none",
              //   WebkitTextFillColor: "transparent",
              //   WebkitBackgroundClip: "text",
              //   WebkitBoxDecorationBreak: "clone",
              //   backgroundClip: "text",
              //   borderColor: "white",
              // },
            },
          },
          "&:h2": {
            fontSize: "20px",
            margin: ".25rem 0 auto",
            textDecoration: "none",
            color: "inherit",
            border: 0,
            display: "inline-block",
            cursor: "pointer",
            "&:hover": {
              background: "linear-gradient(90deg,#ff8a00,#e52e71)",
              textShadow: "none",
              webkitTextFillColor: "transparent",
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
            },
          },
        }}
      >
        <h2>{jobTitle}</h2>
        <ul>{children}</ul>
      </header>
    </article>
    // <a
    //   href={link}
    //   target="_blank"
    //   rel="noreferrer noopener"
    //   sx={{
    //     width: `100%`,
    //     boxShadow: `lg`,
    //     position: `relative`,
    //     textDecoration: `none`,
    //     borderRadius: `lg`,
    //     px: 4,
    //     py: [4, 5],
    //     color: `white`,
    //     background: bg || `none`,
    //     transition: `all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important`,
    //     "&:hover": {
    //       color: `white !important`,
    //       transform: `translateY(-5px)`,
    //       boxShadow: `xl`,
    //     },
    //   }}
    // >
    //   <div
    //     sx={{
    //       textTransform: `uppercase`,
    //       letterSpacing: `wide`,
    //       pt: 4,
    //       fontSize: [4, 5],
    //       fontWeight: `medium`,
    //       lineHeight: 1,
    //     }}
    //   >
    //     {title}
    //   </div>
    //   <div sx={{ opacity: 0.85, textShadow: `0 2px 10px rgba(0, 0, 0, 0.3)` }}>{children}</div>
    // </a>
  )

export default ExperienceCard
