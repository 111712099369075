/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// const scrollTo = (id) => () => {
//     const el = document.querySelector(id)
//     if (el) return window.scrollTo(0, el.offsetTop - 20)
//     return false
// }

// import firebase from "gatsby-plugin-firebase";
// import "firebase/analytics"

const lazyApp =
    import("firebase/app")
const lazyAnalytics =
    import("firebase/analytics")

Promise.all([lazyApp, lazyAnalytics]).then(([firebase]) => {
    firebase.initializeApp({
        apiKey: "AIzaSyD1uFIzkF7VQG24gb5bg2441lamqL1Q2Hw",
        authDomain: "bhasit-portfolio.firebaseapp.com",
        databaseURL: "https://bhasit-portfolio.firebaseio.com",
        projectId: "bhasit-portfolio",
        storageBucket: "bhasit-portfolio.appspot.com",
        messagingSenderId: "821880993874",
        appId: "1:821880993874:web:de3efc3aa49a9e19d0478b",
        measurementId: "G-TBPZ1Q8706",
    })
    firebase.analytics().logEvent("visited_about_us")
})

localStorage.setItem('theme-ui-color-mode', 'dark');