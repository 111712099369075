import React from "react"
import ProjectCard from "../components/project-card"
import ExperienceCard from "../components/experience-card"

const components = {
    // eslint-disable-next-line react/display-name
    ProjectCard: ({ link, title, bg, children }) => ( <
        ProjectCard link = { link }
        title = { title }
        bg = { bg } > { children } <
        /ProjectCard>
    ),
    ExperienceCard: ({ link, title, bg, imgSrc, timeline, jobTitle, children }) => ( <
        ExperienceCard link = { link }
        title = { title }
        imgSrc = { imgSrc }
        timeline = { timeline }
        jobTitle = { jobTitle }
        bg = { bg } > { children } <
        /ExperienceCard>
    ),
}

export default components